import { useQuery } from "~graphql/hooks/shared";
import { GET_FEATURE_FLAGS } from "~graphql/queries/featureFlags";

// 30 second intervals
const FEATURE_FLAG_POLLING_RATE = 30000;

export function useFeatureFlagPolling() {
    const { error, loading, data, refetch } = useQuery(GET_FEATURE_FLAGS, {
        fetchPolicy: "network-only",
        pollInterval: FEATURE_FLAG_POLLING_RATE,
    });

    const featureFlags = data?.getFeatureFlags?.web_hiring;

    return { error, isLoading: loading, featureFlags, refetch };
}
